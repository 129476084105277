import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesAccordion from './components/ServicesAccordion';
import DevelopmentApproach from './components/DevelopmentApproach';
import FooterSection from './components/Footersection'; // Updated import to match your component name
import '@fortawesome/fontawesome-free/css/all.min.css';
import SupportPage from './components/SupportPage';
import { Helmet } from 'react-helmet';  // Importing Helmet

function App() {

  useEffect(() => {
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-6C31HG16YN');
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="App">
      {/* Inject Google Analytics scripts into the head */}
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6C31HG16YN"></script>
      </Helmet>
      
      <Header />
      <HeroSection />
      {/* The ServicesAccordion is placed at the top and can be linked to from the footer */}
      <div id="services-accordion-section">
        <ServicesAccordion />
      </div>
      <SupportPage/>
      <DevelopmentApproach />
      <FooterSection />
    </div>
  );
}

export default App;
