import React, { useEffect, useRef, useState } from 'react';
import '../styles/HeroSection.css';  // Updated path for CSS
import Button from './Button';
import heroImage from '../assets/Hero.png'; // Add the hero image here

const HeroSection = () => {
  const whatsappGroupLink = "https://chat.whatsapp.com/L9Hw1k6YBdb0VCzu1QvkJZ "; // Replace with your actual group link
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const sectionRef = useRef(null); // Ref for the section

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsVisible(true); // Set to true when the section is in view
      } else {
        setIsVisible(false); // Reset to false when out of view
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.5, // Trigger when 50% of the section is visible
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section className={`hero ${isVisible ? 'visible' : ''}`} ref={sectionRef}>
      <div className={`hero-text ${isVisible ? 'visible' : ''}`}>
        <h1 className="animated-heading">
          Where Design Meets<br />
          Innovation!
        </h1>
        <p>
          Whether you're a startup looking to <br /> establish your brand identity or
          an established business aiming to <br /> refresh your image, we've got the
          creative <br /> firepower to make it happen.
        </p>
        {/* "GET IN TOUCH" Button - Route to WhatsApp Group */}
        <p className="whatsapp-invite-text">
          Join our WhatsApp group to stay updated with the latest projects and opportunities.
        </p>
        <a
          href={whatsappGroupLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button text="GET IN TOUCH" />
        </a>
      </div>
      <div className={`hero-image ${isVisible ? 'visible' : ''}`}>
        <img src={heroImage} alt="Creative team" />
      </div>
    </section>
  );
};

export default HeroSection;
