import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com'; // Import emailjs for sending emails
import '../styles/SupportPage.css'; // Import the CSS file for styles

const supportItems = [
    { title: "AI/ML" },
    { title: "Chatbots" },
    { title: "Web Application Development" },
    { title: "Mobile Application Development" },
    { title: "E-commerce Platforms" },
    { title: "IoT Solutions" },
];

const SupportPage = () => {
    const sectionRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ title: '', name: '', email: '',phone: '', message: '' });
    const [loading, setLoading] = useState(false); // State to track loading status

    // Handle ball click and open the popup
    const handleBallClick = (title) => {
        setFormData({ ...formData, title });
        setShowPopup(true);
    };

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when form is submitted
    
        // Email.js send method
        emailjs
            .send(
                'service_7zjj3lr', // Replace with your service ID
                'template_r46f6ne', // Replace with your template ID
                {
                    title: formData.title,
                    name: formData.name,
                    email: formData.email,
                    phone:formData.phone,
                    message: formData.message, // Include the message field
                },
                'Ge5l1rMWAVIvn4WHT' // Replace with your public key
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setLoading(false); // Stop loading after successful response
                setShowPopup(false); // Close popup after sending email
                alert('Your request has been sent! We will contact you within 2 hours.');
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setLoading(false); // Stop loading if there is an error
                alert('There was an error. Please try again later.');
            });
    };
    

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate'); // Trigger animation
                } else {
                    entry.target.classList.remove('animate'); // Reset animation when out of view
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.1,
        });

        const ballItems = sectionRef.current.querySelectorAll('.ball');
        ballItems.forEach((item) => observer.observe(item));

        return () => {
            ballItems.forEach((item) => observer.unobserve(item)); // Clean up observer when component unmounts
        };
    }, []);

    return (
        <div ref={sectionRef} className="support-container">
            <div className="rainbow-line"></div>
            <h2>Our Support Offerings</h2>
            <div className="support-items">
                {supportItems.map((item, index) => (
                    <div key={index} className="support-item">
                        <div className="ball" onClick={() => handleBallClick(item.title)}>
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                ))}
            </div>

            {/* Popup Modal */}
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>{formData.title}</h2>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone</label>
                                <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                                pattern="[0-9]{10,15}" // Only digits allowed, and length between 10 and 15
                                minLength="10"
                                maxLength="13"
                                title="Please enter a valid phone number"

                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    name="message"
                                    value={formData.message || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button type="submit" disabled={loading}>
                                {loading ? (
                                    <div className="spinner"></div> // Show spinner while loading
                                ) : (
                                    'Submit' // Default text when not loading
                                )}
                            </button>
                        </form>

                        <button className="popup-close" onClick={() => setShowPopup(false)}>
                            &#10005;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SupportPage;
