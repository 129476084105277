import React, { useEffect, useState, useRef } from 'react';
import '../styles/DevelopmentApproach.css';
import sharedUnderstandingIcon from '../assets/dev.png';
import experienceIcon from '../assets/prove.png';
import securityIcon from '../assets/security.png';
import codeReviewIcon from '../assets/review.png';
import qaIcon from '../assets/secure.png';
import bigQImage from '../assets/Q.png'; // Import your PNG file
import UI from '../assets/UI.png'

const DevelopmentApproach = () => {
  const approachItems = [
    {
      title: "UX Driven Engineering",
      content: "Unlike other companies, we are a UX first development company. Projects are driven by designers and they make sure design and experiences translate to code.",
      icon: <img src={UI} alt="Driven Engineering" />
    },
    {
      title: "Developing Shared Understanding",
      content: "We ensure clear communication and shared goals with clients to make sure design and experiences translate seamlessly into the development process.",
      icon: <img src={sharedUnderstandingIcon} alt="Shared Understanding" />
    },
    {
      title: "Proven Experience and Expertise",
      content: "Our team brings years of expertise in designing and developing solutions that solve real-world problems, with a focus on innovation.",
      icon: <img src={experienceIcon} alt="Experience and Expertise" />
    },
    {
      title: "Security & Intellectual Property (IP)",
      content: "We take security seriously. Our development practices include rigorous testing to ensure data protection and intellectual property security.",
      icon: <img src={securityIcon} alt="Security" />
    },
    {
      title: "Code Reviews",
      content: "All code goes through a stringent review process to maintain high standards of quality, ensuring bug-free and maintainable code.",
      icon: <img src={codeReviewIcon} alt="Code Review" />
    },
    {
      title: "Quality Assurance & Testing",
      content: "We follow best practices in quality assurance, ensuring that every product we deliver is thoroughly tested and meets your expectations.",
      icon: <img src={qaIcon} alt="Quality Assurance" />
    }
  ];

  const [typedText, setTypedText] = useState('');
  const sectionRef = useRef(null); // Ref for the Qwat animation section
  const gridRef = useRef(null); // Ref for the approach cards

  useEffect(() => {
    const text = 'Qwat';
    let index = 0;
    let typingInterval;

    const startTypingAnimation = () => {
      setTypedText('');  // Reset text when the section is reached again
      index = 0;  // Reset the index
      typingInterval = setInterval(() => {
        if (index < text.length) {
          setTypedText((prev) => prev + text[index]);
          index++;
        } else {
          clearInterval(typingInterval);
        }
      }, 500);
    };

    // Handle typing animation for the 'Qwat'
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        startTypingAnimation();  // Start typing animation every time it's in view
      }
    };

    // Handle animation for approach cards when they come into view
    const handleGridIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');  // Trigger animation
        } else {
          entry.target.classList.remove('animate'); // Reset animation when out of view
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // use viewport as root
      threshold: 0.5, // trigger when 50% of the section is visible
    });

    const gridObserver = new IntersectionObserver(handleGridIntersection, {
      root: null, // use viewport as root
      threshold: 0.1, // trigger when 10% of the cards are visible
    });

    // Observe the Qwat animation section
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Observe each grid card
    const gridItems = gridRef.current.querySelectorAll('.approach-card');
    gridItems.forEach((item) => gridObserver.observe(item));

    return () => {
      clearInterval(typingInterval);
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (gridItems) {
        gridItems.forEach((item) => gridObserver.unobserve(item));
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="development-approach-container">
      {/* Rainbow Line */}
      <div className="rainbow-line"></div>
      <p1>Our Design and</p1>
      <h2 className="section-title">Development Approach</h2>

      <div ref={gridRef} className="approach-grid">
        {approachItems.map((item, index) => (
          <div key={index} className="approach-card">
            <div className="icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
          </div>
        ))}
      </div>

      {/* Qwat Animation (placed below the approach grid) */}
      <div className="qwat-animation">
      <div className="big-q red">
          <img src={bigQImage} alt="Big Q" className="big-q-image" />
        </div>
        <div className="typed-text">
          <span className="letter red">{typedText.slice(0, 1)}</span>
          <span className="letter black">{typedText.slice(1)}</span>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentApproach;
