import React from 'react';
import '../styles/Header.css';  // Updated path for CSS
import logo from '../assets/QWAT.png'; // Import logo

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="QWAT Logo" />
      </div>
      <nav>

      </nav>
      {/* Changed button to a link that opens WhatsApp */}
      <a 
        href="https://wa.me/919080870732"  // WhatsApp link with phone number
        target="_blank" 
        rel="noopener noreferrer"
        className="contact-btn"
      >
        LET'S TALK
      </a>
    </header>
  );
};

export default Header;
